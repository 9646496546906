<template>
  <section class="container mb-10 lg:mb-20 overflow-hidden" v-if="category" :id="category?.slug">
    <div class="flex justify-between">
      <GlobalHeading data-aos="fade-right" data-aos-once="true" :title="category?.title"></GlobalHeading>
      <nuxt-link
          :to="category?.url"
          class="text-accent-01 text-sm leading-5 uppercase font-semibold hover:text-primary duration-200 hidden lg:flex items-end"
      >
        <span> {{ t('SHOW_MORE') }}</span>
        <span class="w-4.5 h-4.5 i-custom-chevron-right"></span>
      </nuxt-link>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-2 divide-bw-08">
      <item-new
          data-aos="fade-up"
          data-aos-once="true"
          @click="removeAttribute(news)"
          v-for="(news, index) in dataRender"
          class="border-b border-bw-08 py-12 removeAnimationFeature"
          :key="news?.id"
          :dataItem="news"
          :class="[
                    index == 4 || index == 5 || dataRender.length < 3 ? 'lg:border-none lg:pb-0' : '',
                    index % 2 == 0 ? 'xl:pr-15 lg:pr-5' : 'xl:pl-15 lg:pl-5'
                ]"
      >
      </item-new>
    </div>
    <nuxt-link
        :to="category?.url"
        class="text-primary hover:text-accent-01 duration-300 items-center flex mt-8 md:hidden"
    >
      <span class="uppercase text-sm font-semibold leading-5">{{ t('SHOW_MORE') }}</span>
      <span class="w-4.5 h-4.5 i-custom-chevron-right"></span>
    </nuxt-link>
  </section>
</template>

<script lang="ts">
export default {
  name: 'sectionLayout2'
}
</script>

<script setup lang="ts">
import ItemNew from './__ItemNew.vue'

import {computed} from 'vue'
import {NEWS_EVENT_ID, useEventTracking} from "../../../composables/ackee/event";

const {getLatestNewsByCategory} = useIZNewsWidgets()
const {t} = useI18n()

const props = defineProps({
  category: {
    type: Object as any
  }
})

const removeAttribute = () => {
  useEventTracking(NEWS_EVENT_ID, `${firstItem?.category?.title} - ${firstItem?.title}`)

  document.querySelectorAll('.removeAnimationFeature').forEach((item) => {
    item.removeAttribute('data-aos')
  })
}

const route = useRoute()

const res = await getLatestNewsByCategory(props.category?.id, route.params.langCode, {
  query: {
    limit: 6
  }
})

const dataRender = computed(() => {
  return res
})
</script>

<style></style>
