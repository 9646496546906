<template>
  <nuxt-link
      :to="dataItem?.url"
      @click="trackingNews(dataItem)"
      class="flex gap-4 lg:gap-7 lg:flex-row flex-row-reverse items-center group cursor-pointer"
  >
    <div class="w-41 h-full md:w-74 aspect-41/25 lg:aspect-74/45 rounded-15px overflow-hidden">
      <img
          :src="getThumbnail(dataItem?.thumbnail?.id, 295, true)"
          :alt="dataItem?.thumbnail?.description || dataItem?.thumbnail?.title"
          class="w-full h-full object-cover group-hover:scale-105 transfrom duration-300"
      />
    </div>
    <div class="flex flex-col flex-1 lg:self-auto self-stretch">
      <div
          class="items-center gap-2.5 text-bw-04 font-medium uppercase text-13px leading-6 mb-0.5 xl:flex hidden font-semibold"
      >
        <p>
          {{ dataItem?.category?.title }}
        </p>
        <div class="h-13px w-1px bg-black/20"></div>

        <p>
          {{ dataItem?.date_created }}
        </p>
      </div>
      <h3
          class="text-17px leading-6 text-black mb-2 group-hover:text-primary duration-200 lg:line-clamp-2 font-semibold flex-1 line-clamp-4"
      >
        {{ dataItem?.title }}
      </h3>
      <p class="text-bw-04 font-semibold uppercase text-13px leading-6 flex lg:hidden">
        {{ dataItem?.category?.title }}
      </p>
      <p class="!lg:block !hidden">
        <span class="text-black/60 !lg:line-clamp-3">{{ dataItem?.intro }}</span>
      </p>
    </div>
  </nuxt-link>
</template>

<script lang="ts">
export default {
  name: 'item-new'
}
</script>

<script setup lang="ts">
import {NEWS_EVENT_ID, useEventTracking} from "../../../composables/ackee/event";

defineProps({
  dataItem: {
    type: Object as any
  }
})

const trackingNews = (dataItem: any) => {
  useEventTracking(NEWS_EVENT_ID, `${dataItem?.category?.title} - ${dataItem?.title}`)
}
</script>

<style></style>
