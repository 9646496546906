<template>
  <banner :title="t('LATEST_NEWS')"></banner>
  <navigation :data="categories"></navigation>
  <section-ontop-index></section-ontop-index>
  <section-layout1 :category="firstCategories"></section-layout1>
  <section-download></section-download>


  <template v-for="(item, index) in remainningCategories">
    <template v-if="(index + 1) % 2 == 0">
      <section-layout1 :category="item"></section-layout1>
    </template>
    <template v-else>
      <section-layout2 :category="item"></section-layout2>
    </template>
  </template>
  <section-signUp></section-signUp>
</template>

<script setup lang="ts">
import Banner from './components/Banner.vue'
import Navigation from './components/Navigation.vue'
import SectionOntopIndex from './components/SectionOntopIndex.vue'
import SectionLayout1 from './components/SectionLayout1.vue'
import SectionLayout2 from './components/SectionLayout2.vue'
import SectionDownload from './components/SectionDownload.vue'
import SectionSignUp from './components/SectionSignUp.vue'
import {useGetLangCurrent} from '../../stores/useLanguageMenu'

const {getFeaturedCategories} = useIZNewsWidgets()
const {t} = useI18n()

const categories = ref<Object[]>([])

const res = await getFeaturedCategories(useRoute().params.langCode, {})
categories.value.push(...res)

const firstCategories = computed(() => {
  return categories.value[0] ?? {}
})
const remainningCategories = computed(() => {
  return categories.value.slice(1)
})

const settings = inject('globalSettings')
const {generateLangSwitch} = useGenerateUrl(settings)
const lang_switch = generateLangSwitch('news', useRoute().params.langCode)
useDirectusCollectionSeo('news_index', {})
const store = useGetLangCurrent()
onMounted(() => {
  store.lang_switch = lang_switch
})
</script>

<style></style>
