<template>
  <section class="container relative overflow-hidden" id="tin-tuc" v-if="dataRender && dataRender.length > 0">
    <div class="flex flex-col lg:flex-row lg:gap-8 mb-10 lg:border-none border-b border-bw-08 pb-8 lg:mb-20">
      <nuxt-link
          data-aos="fade-right"
          data-aos-once="true"
          :to="firstItem?.url"
          @click="removeAttribute"
          class="flex flex-col lg:border-none border-b lg:pb-0 pb-8 border-bw-08 flex-1 group removeAnimationFeature"
      >
        <div class="aspect-178/120 w-full rounded-15px overflow-hidden mb-6">
          <img
              :src="getThumbnail(firstItem?.thumbnail?.id, 751, true)"
              :alt="firstItem?.thumbnail?.description || firstItem?.thumbnail?.title"
              class="w-full h-full object-cover"
          />
        </div>
        <div class="w-full">
          <p class="flex gap-2 uppercase divide-x divide-bw-04 text-bw-04 text-13px leading-6 font-semibold">
            {{ firstItem?.category?.title }}
          </p>
          <p class="leading-30px text-24px font-[550] text-black mb-2 group-hover:text-primary duration-400">
            {{ firstItem?.title }}
          </p>
          <p class="text-black/60 mt-6 lg:block hidden">
            {{ firstItem?.intro }}
          </p>
        </div>
      </nuxt-link>
      <div class="lg:w-108 lg:pl-8 lg:border-l border-bw-08">
        <h4
            data-aos="fade-left"
            data-aos-once="true"
            class="font-semibold leading-6 text-15px text-primary uppercase pb-6 border-b border-bw-08 lg:block hidden"
        >
          {{ t('READ_A_LOT_IN_THE_LAST_24H') }}
        </h4>
        <div
            data-aos="fade-left"
            data-aos-once="true"
            class="flex flex-col gap-8 divide-y-1 divide-bw-08 max-h-160 lg:max-h-170 overflow-x-hidden overflow-y-auto custom-scrollbar-global"
            :class="remainningData.length > 5 ? 'pr-4' : ''"
        >
          <GlobalItemListNew
              v-for="news in remainningData"
              :dataItem="news"
              :key="news?.id"
              class="pt-8"
              @click="trackingNews(news)"
          ></GlobalItemListNew>
        </div>
      </div>
    </div>
    <!-- <div class="pt-20 mb-20 border-bw-08 border-t lg:block hidden" v-if="lastData && lastData.length > 0">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-5">
            <GlobalItemGridNew
                data-aos="fade-up"
                data-aos-once="true"
                @click="removeAttribute"
                class="removeAnimationFeature"
                v-for="news in lastData"
                :key="news?.id"
                :dataItem="news"
            >
            </GlobalItemGridNew>
        </div>
    </div> -->
  </section>
</template>
<script lang="ts">
export default {
  name: 'sectionNewNews'
}
</script>

<script setup lang="ts">
import {
  NEWS_EVENT_ID,
  useEventTracking
} from "../../../composables/ackee/event";

const {getOnTop} = useIZNewsWidgets()
const {t} = useI18n()

const removeAttribute = (firstItem: any) => {
  useEventTracking(NEWS_EVENT_ID, `${firstItem?.category?.title} - ${firstItem?.title}`)

  document.querySelectorAll('.removeAnimationFeature').forEach((item) => {
    item.removeAttribute('data-aos')
  })
}

const trackingNews = (dataItem: any) => {
  useEventTracking(NEWS_EVENT_ID, `${dataItem?.category?.title} - ${dataItem?.title}`)
}

const dataRender = ref([])
const route = useRoute()
const res = await getOnTop(route.params.langCode, {})
dataRender.value = res

const firstItem = computed(() => {
  return dataRender.value[0] ?? {}
})

const remainningData = computed(() => {
  return dataRender.value.slice(1)
})

const lastData = computed(() => {
  return dataRender.value.slice(5)
})
</script>

<style></style>
