<template>
  <section
      class="index-section-1 container overflow-hidden relative mb-10 lg:mb-20"
      v-if="category"
      :id="category?.slug"
  >
    <div class="mb-12 flex justify-between">
      <GlobalHeading data-aos="fade-right" data-aos-once="true" :title="category?.title"></GlobalHeading>
      <nuxt-link
          :to="category?.url"
          class="text-accent-01 text-sm leading-5 uppercase font-semibold hover:text-primary duration-200 hidden lg:flex items-end"
      >
        <span> {{ t('SHOW_MORE') }}</span>
        <span class="w-4.5 h-4.5 i-custom-chevron-right"></span>
      </nuxt-link>
    </div>
    <div class="flex flex-col lg:flex-row gap-8">
      <nuxt-link
          data-aos="fade-right"
          data-aos-once="true"
          :to="firstItem?.url"
          @click="removeAttribute(firstItem)"
          class="flex flex-col lg:border-none border-b lg:pb-0 pb-8 border-bw-08 flex-1 group removeAnimationFeature"
      >
        <div class="aspect-178/120 w-full rounded-15px overflow-hidden mb-6">
          <img
              :src="getThumbnail(firstItem?.thumbnail?.id, 715, true)"
              :alt="firstItem?.thumbnail?.description || firstItem?.thumbnail?.title"
              class="w-full h-full object-cover transfrom group-hover:scale-105 duration-300"
          />
        </div>
        <div class="w-full">
          <p class="flex gap-2 uppercase divide-x divide-bw-04 text-bw-04 text-13px leading-6 font-semibold">
            {{ firstItem?.category?.title }}
          </p>
          <p class="leading-6 text-lg font-[550] text-black mb-2 group-hover:text-primary duration-400">
            {{ firstItem?.title }}
          </p>
          <p class="text-black/60 mt-6 lg:block hidden">{{ firstItem?.intro }}</p>
        </div>
      </nuxt-link>

      <div class="lg:w-108 lg:pl-8 lg:border-l border-bw-08">
        <div class="flex flex-col divide-y divide-bw-08 gap-8">
          <GlobalItemListNew
              data-aos="fade-left"
              data-aos-once="true"
              class="pt-8 removeAnimationFeature"
              v-for="(news, index) in itemsRender"
              @click="removeAttribute(news)"
              :key="news?.id"
              :dataItem="news"
              :class="index == 0 ? '!pt-0' : ''"
          ></GlobalItemListNew>
        </div>

        <nuxt-link
            :to="category?.url"
            class="text-accent-01 text-sm leading-5 uppercase font-semibold hover:text-primary duration-200 lg:hidden flex border-t border-bw-08 pt-8 mt-4"
        >
          <span> {{ t('SHOW_MORE') }}</span>
          <span class="w-4.5 h-4.5 i-custom-chevron-right"></span>
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
export default {
  name: 'IndexLayout1'
}
</script>
<script setup lang="ts">
import {computed} from 'vue'
import {NEWS_EVENT_ID, useEventTracking} from "../../../composables/ackee/event";

const {getLatestNewsByCategory} = useIZNewsWidgets()
const {t} = useI18n()
const props = defineProps({
  category: {
    type: Object,
    require: true
  }
})

const route = useRoute()
const res = await getLatestNewsByCategory(props.category?.id, route.params.langCode, {
  query: {
    limit: 5
  }
})

const removeAttribute = (firstItem: any) => {
  useEventTracking(NEWS_EVENT_ID, `${firstItem?.category?.title} - ${firstItem?.title}`)

  document.querySelectorAll('.removeAnimationFeature').forEach((item) => {
    item.removeAttribute('data-aos')
  })
}

const itemsRender = computed(() => {
  return res.slice(1)
})

const firstItem = computed(() => {
  return res[0] ?? {}
})
</script>

<style></style>
